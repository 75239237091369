// Blog Post Pages //

.post-wrapper
  display: flex
  flex-direction: row
  justify-content: center
  align-items: flex-start

%post-sidebar
  width: 240px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-around
  padding: 20px
  clear: none
  background-color: rgba(0, 0, 0, 0.0)
  margin-top: 1000px

  iframe
    margin: auto

.post-sidebar-left
  @extend %post-sidebar
  float: left
  margin-left: calc(100vw / 8)
  // margin-left: 100px
  // margin-top: 400px


.post-sidebar-right
  @extend %post-sidebar
  float: right
  margin-right: calc(100vw / 8)
  // margin-top: 800px

.post-main
  @extend %page-main
  background-color: $white
  color: $black
  clear: none
  // display: flex
  // flex-direction: column

  h1, h2, h3, h4, h5, h6

    a
      color: inherit
      text-decoration: underline
      text-decoration-color: $boldpurple

  p, li

    a
      color: $boldpurple
      text-decoration: none

      &:hover
        text-decoration: underline
        text-decoration-color: $boldpurple

  blockquote
    color: $black
    font-family: $open-sans
    font-size: 1.1rem
    font-weight: 300
    line-height: 2rem
    background-color: $purple-background
    border-left: 7px solid $lighterboldpurple
    margin: 30px 90px
    padding: 20px

    %blockquote-text-base
      font-family: inherit
      font-size: inherit
      line-height: inherit
      font-weight: inherit

    p
      @extend %blockquote-text-base

      a
        color: $lighterboldpurple
        font-weight: 400

  p.warning
    color: $black
    font-family: $open-sans
    font-size: 1.1rem
    font-weight: 300
    line-height: 2rem
    background-color: $purple-background
    padding: 20px
    border-style: solid
    border-color: $lighterboldpurple
    border-width: 3px

    a
      color: $lighterboldpurple
      font-weight: 400

    strong
      font-weight: 600
      // text-transform: uppercase

  p.center
    text-align: center

  p.special
    font-family: $bebas
    font-size: 4rem
    // line-height: 3.5rem
    color: $black
    margin: 40px auto 10px auto

    a
      color: inherit
      text-decoration: underline
      text-decoration-color: $boldpurple

  hr
    color: $purple-background
    width: 90%
    height: 0.5px

  table

    tr
      margin: 10px

      th
        padding: 10px
        width: 50%
        color: $black
        font-family: $open-sans
        font-size: 1.1rem
        font-weight: 600
        line-height: 2rem
        text-transform: uppercase

        a
          color: $lighterboldpurple
          font-weight: 400

      td
        background-color: $purple-background
        color: $black
        font-family: $open-sans
        font-size: 1.1rem
        font-weight: 300
        line-height: 2rem
        vertical-align: top
        padding: 10px

        a
          color: $lighterboldpurple
          font-weight: 400

  .author-info
    font-family: $open-sans
    font-size: 1.1rem
    font-weight: 300
    line-height: 2rem
    font-style: italic

    a
      color: $lighterboldpurple
      font-weight: 400


  .interview-question
    font-family: $open-sans
    color: $boldpurple
    font-size: 1.2rem
    font-weight: 600
    line-height: 1.7rem

  p img
    max-width: 100%
    margin: auto
    display: block

.post-image
  position: absolute
  z-index: -99

  img
    max-width: 100vw

.post-wrapper-with-image
  @extend .post-wrapper
  width: 600px
  margin: 30vh auto auto auto
  background-color: $white
  // padding: auto 30px

.post-main-with-image
  @extend .post-main
  width: 600px

  h1
    width: 90%
    max-width: 500px
    align-self: center
    margin-left: auto
    margin-right: auto

  h2
    margin-left: 20px
    margin-right: 20px
