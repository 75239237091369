// HEADER //

/* Standard Header */

%header-base
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  height: 50px
  // width: 100vw
  width: 100%
  padding: 45px 25px

.header-home
  @extend %header-base
  position: absolute
  background-color: rgba(0, 0, 0, 0.0)

.header-transparent
  @extend %header-base
  background-color: rgba(0, 0, 0, 0.0)

.header-gradient
  @extend %header-base
  background-image: radial-gradient($charcoal, $black)

.header-logo
  float: left

.header-cw-logo-img
  max-width: 222px
  max-height: 33px

/* Full-Width Header */
.header-nav-full
  display: flex
  flex-direction: row
  justify-content: center
  float: right

.header-ul
  display: flex
  flex-direction: row
  margin: 0

.header-li
  display: inline-flex
  align-content: center
  padding: 10px

.header-a
  font-family: $bebas
  color: $white
  font-size: 2rem
  text-decoration: none
  padding: 10px

.header-a
  &:hover
    background-color: $white
    color: $black

/* Mobile Header */
.header-bars-mobile
  display: none
  flex-direction: row
  justify-content: center
  align-content: center
  float: right
  margin-right: 30px

.mobile-header-ul
  margin: 0
  padding: 0

.mobile-header-li
  margin: 0
  padding: 0
  list-style-type: none

.mobile-header-a
  color: $black
  text-decoration: none
  font-family: $roboto
  font-size: 1.1rem
  text-transform: uppercase
  font-weight: 300
  line-height: 2.7rem

  &:hover
    color: $lavender
    // text-decoration: underline

  &:active
    color: $lavender
    // text-decoration: underline

#mobile-header-menu
  display: none
  position: absolute
  z-index: 1
  right: 10px
  top: 75px
  float: right
  background-color: $white
  font-family: $roboto
  padding: 5px
  flex-direction: column
  padding: 20px
  align-self: flex-start
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7)
