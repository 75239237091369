// AD STYLES //

// Ad Wrapper Div //

.ad-wrapper-desktop
  display: inline

.ad-wrapper-mobile
  display: none

// Ad iframe //

%ad-iframe-base
  border-width: 0
  height: min-content
  width: 100%
  display: inline
  margin: 20px auto
  padding: 0

  html
    margin: 0
    padding: 0

    body
      margin: 0
      padding: 0


.ad-iframe-book
  @extend %ad-iframe-base
  min-height: 320px

.ad-iframe-apparel
  @extend %ad-iframe-base
  min-height: 250px


// Ad Base //

.ad-base
  background-color: $black
  display: flex
  flex-direction: row
  width: 100%
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7)
  align-content: center
  justify-content: center
  border-style: solid
  border-color: $black
  border-width: 1px
  height: min-content

%ad-flex
  display: flex
  flex-direction: row

.ad-book
  @extend %ad-flex

.ad-apparel
  @extend %ad-flex
  width: 100%
  align-items: center
  justify-content: space-around
  height: min-content

.ad-merch
  @extend %ad-flex

.ad-giftcard
  @extend %ad-flex


// Ad Image //

%ad-image
  background-color: $black
  width: 200px
  padding: 0
  margin: 0 10px 0 0
  display: flex
  flex-direction: column
  align-content: center
  justify-content: center

  img
    background-color: $white
    width: 200px
    margin: 0 10px 0 0
    padding: 0
    border-right-style: solid
    border-right-color: $charcoal
    border-right-width: 1px

.ad-image-book
  @extend %ad-image
  height: min-content

  img
    height: min-content

.ad-image-apparel
  @extend %ad-image
  height: min-content
  margin: 0

  img
    height: min-content

.ad-image-merch
  @extend %ad-image

.ad-image-giftcard
  @extend %ad-image


// Ad Text //

%ad-title-base
  font-family: $bebas
  font-size: 1.5rem
  text-align: center

%ad-description-base
  font-family: $open-sans
  font-size: $ad-description-font-size-large
  font-weight: 300
  line-height: 1.3rem

%ad-text-base
  margin: 10px
  display: flex
  flex-direction: column
  padding: 10px
  background-image: radial-gradient($charcoal, $black)
  color: $white

.ad-text
  @extend %ad-text-base


// Apparel Text //

.ad-apparel-text
  @extend %ad-text-base
  width: 400px
  max-height: 250px
  text-align: center

.ad-apparel-name
  @extend %ad-title-base
  margin: 5px

.ad-apparel-description
  @extend %ad-description-base


// Book Text //

.ad-book-text
  @extend %ad-text-base
  max-width: 400px

.ad-book-name
  @extend %ad-title-base

.ad-book-description
  @extend %ad-description-base

.ad-book-review
  @extend %ad-description-base
  font-style: italic


// Merchandise Text //

.ad-merch-text
  @extend %ad-text-base

.ad-merch-name
  @extend %ad-title-base

.ad-merch-description
  @extend %ad-description-base


// Giftcard Text //

.ad-giftcard-text
  @extend %ad-text-base

.ad-giftcard-name
  @extend %ad-title-base

.ad-giftcard-description
  @extend %ad-description-base


// Ad Call to Action

%ad-cta-base
  background-color: rgba(0, 0, 0, 0)
  width: 80%
  margin: auto
  padding: 0

  a
    text-decoration: none
    color: $white

  &:hover
    cursor: pointer

    a
      color: $black

%ad-button-base
  background-color: $black
  margin: 5px
  padding: 2px
  border-style: solid
  border-width: 1px
  border-color: $white
  color: $white

  &:hover
    background-color: $white
    color: $black

  p
    text-align: center
    font-family: $roboto
    text-transform: uppercase
    font-weight: 300
    letter-spacing: 2px
    font-size: 0.8rem

.ad-book-cta
  @extend %ad-cta-base

.ad-book-button
  @extend %ad-button-base

.ad-apparel-cta
  @extend %ad-cta-base

.ad-apparel-button
  @extend %ad-button-base

.ad-merch-cta
  @extend %ad-cta-base

.ad-merch-button
  @extend %ad-button-base

.ad-giftcard-cta
  @extend %ad-cta-base

.ad-giftcard-button
  @extend %ad-button-base
