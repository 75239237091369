// HOME //

%home-block
  height: 100vh
  width: 100%
  text-align: center
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.home-block-black
  @extend %home-block
  background-image: radial-gradient($charcoal, $black)
  color: $white

.home-block-white
  @extend %home-block
  background-color: $white
  color: $black

.homea
  font-family: $bebas
  font-size: 10rem
  font-weight: 400
  letter-spacing: 0px
  line-height: 1
  text-transform: uppercase
  padding: 2%
  margin: 0

.homeb
  font-family: $open-sans
  font-size: 2.3rem
  font-weight: 400
  letter-spacing: 10px
  line-height: 1.6
  text-transform: uppercase
  padding: 2%
  margin: 0

.homec
  font-family: $open-sans
  font-size: 2.3rem
  font-weight: 400
  letter-spacing: 10px
  line-height: 1.6
  text-transform: uppercase
  padding: 2%
  margin: 0

.homed
  font-family: $bebas
  font-size: 10rem
  font-weight: 400
  letter-spacing: 0px
  line-height: 1
  text-transform: uppercase
  padding: 2%
  margin: 0

#joy
  font-size: 18rem

#defeated
  font-size: 13rem
