// BLOG //

.post-body
  // display: flex
  // flex-direction: column

.blog-main
  min-height: min-content
  max-width: 1400px
  margin: auto
  margin-bottom: 200px
  background-color: rgba(0, 0, 0, 0.0)

  %heading-base
    font-family: $bebas
    margin-top: 40px
    margin-right: auto
    margin-bottom: 10px

  %text-base
    font-family: $minion
    font-size: 1.6rem
    line-height: 2.8rem
    max-width: 500px
    margin: auto

  h1
    @extend %heading-base
    font-size: 5rem
    text-align: center

  h2
    @extend %heading-base
    font-size: 4rem
    text-align: left
    margin-left: -30px

  h3
    @extend %heading-base
    font-size: 3rem
    text-align: left
    margin-left: 20px

  h4
    @extend %heading-base
    font-size: 2.5rem
    text-align: left
    margin-left: 20px

  h5
    @extend %heading-base
    font-size: 2rem
    text-align: left
    margin-left: 50px

  h6
    @extend %heading-base
    font-size: 1.7rem
    text-align: left

  p
    margin-top: 10px
    margin-bottom: 20px
    width: 100%

// Blog Archive/Collection //

.blog-collection-title
  color: $black
  text-decoration: none

  &:hover
    text-decoration: underline
    text-decoration-color: $boldpurple

.blog-category-menu
  display: block
  max-width: 70%
  margin: auto

.blog-category-menu-mobile-title
  display: none

.blog-category-nav
  margin: 30px

  ul
    display: flex
    flex-direction: row
    justify-content: center
    padding: 0

    li
      list-style-type: none
      font-family: $roboto
      font-size: 0.9rem
      font-weight: 300
      text-transform: uppercase
      text-align: center
      letter-spacing: 3px
      margin: 20px

      a
        text-decoration: none
        color: $black

        &:hover
          color: $boldpurple

      a.current-blog-category
        color: $boldpurple

.blog-post-collection
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: center

.blog-post-card
  background-color: $white
  width: 350px
  height: min-content
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7)
  margin: 30px
  padding: 0
  flex-shrink: 0

.blog-post-card-image

  img
    width: 350px

.blog-post-card-text
  margin: 15px
  padding: 20px 5px
  padding-bottom: 35px

.blog-post-card-title
  font-family: $bebas
  font-size: 3rem
  color: $black
  text-decoration: none
  text-transform: uppercase
  margin: 30px 0 20px 0

  &:hover
    text-decoration: underline

p.blog-post-card-date
  font-family: $roboto
  font-weight: 300
  font-size: 0.9rem
  text-transform: uppercase
  color: $boldpurple
  letter-spacing: 2px
  margin: 10px 0 30px 0

.blog-post-card-excerpt
  font-family: $minion
  font-size: 1.4rem
  font-weight: normal
  line-height: 2.2rem
  margin: 20px 0
  margin-bottom: 35px

.blog-post-card-read-more
  font-family: $roboto
  font-size: 0.9rem
  text-transform: uppercase
  color: $boldpurple
  font-weight: bold
  text-decoration: none
