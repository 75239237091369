// BASE STYLES //

*, *::before, *::after
  box-sizing: border-box

html
  margin: 0
  padding: 0
  // width: 100vw
  width: 100%
  box-sizing: border-box

body
  margin: 0
  padding: 0
  min-height: 100%
  font-size: 12pt
  width: 100%
  overflow-x: hidden

iframe.ad-iframe
  width: 100%

.show-arrow
  display: inline

.hide-arrow
  display: none

#down-arrow
  display: none
  // width: 0
  // height: 0
  // border-left: 5px solid transparent
  // border-right: 5px solid transparent
  // border-top: 5px solid #000

#up-arrow
  display: none
  // width: 0
  // height: 0
  // border-left: 5px solid transparent
  // border-right: 5px solid transparent
  // border-top: 5px solid #000
