// MEDIA QUERIES //

/* Mobile Phones (Portrait) */
@media screen and (orientation: portrait) and (max-width: 480px)

  /* Header */

  .header-cw-logo-img
    // max-width: 200px
    // max-height: 29.7px
    max-width: 175px
    max-height: 26px

  .header-nav-full
    display: none

  .header-bars-mobile
    display: flex

  .header-bars-mobile
    margin-right: 10px

  #mobile-header-menu
    display: none

  /* Homepage */

  .homea
    font-size: 4rem
    padding: 5%

  .homeb
    font-size: 1.2rem
    letter-spacing: 5px
    padding: 5%

  .homec
    font-size: 1.2rem
    letter-spacing: 5px
    padding: 5%

  .homed
    font-size: 4rem
    padding: 5%

  #joy
    font-size: 10rem

  #defeated
    font-size: 6rem

  /* Pages */

  %page-main
    max-width: 90vw

    %text-base
      font-size: 1.5rem
      line-height: 2.5rem

    %heading-base
      margin: 30px auto 5px auto

    h1
      font-size: 4rem
      text-align: center
      width: 100%

    h2
      font-size: 3rem
      text-align: left
      margin-left: 0

    h3
      font-size: 2.5rem
      text-align: left
      margin-left: 0

    h4
      font-size: 2rem
      text-align: left
      margin-left: 0

    h5
      font-size: 1.8rem
      text-align: left
      margin-left: 0

    h6
      font-size: 1.7rem
      text-align: left
      margin-left: 0

    li
      margin-left: 20px
      margin-right: 40px

  .page-light

    blockquote
      margin: 30px 30px

  .page-wide
    max-width: 90vw

  /* Pages - Epilogue */

  p.epilogue-paragraph
    font-family: $roboto
    text-transform: uppercase
    font-weight: 300
    font-size: 1rem
    line-height: 1.7rem
    letter-spacing: 1px
    text-align: center
    margin: 20px auto

    a
      color: $white

      &:hover
        color: $lavender

  /* Pages - For Readers */

  .for-readers-display
    margin-top: 30px
    flex-wrap: wrap
    width: 90vw
    justify-content: center
    align-items: center

  .for-readers-card
    width: 90vw

  .for-readers-image
    // width: 330px
    // height: 181.5px
    width: 290px
    height: 159.5px

  .for-readers-text-link
    font-size: 1.8rem

  /* Pages - Spoiler Section */

  .spoiler-section-book-display
    display: flex
    flex-direction: column

  .spoiler-section-card
    margin-bottom: 30px

  .spoiler-post-display
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    align-items: flex-start
    align-self: center
    width: 90vw

    .blog-post-card
      align-self: center

  /* Pages - Kamsa */

  .kamsa-book-display
    width: 90vw

  .kamsa-book-cover
    margin: 5px 5px

  .kamsa-blog-post-display
    width: 90vw

  /* Collections */

  %collection-main
    max-width: 90vw

    %text-base
      font-size: 1.5rem
      line-height: 2.5rem

    %heading-base
      margin: 30px auto 5px auto

  /* Books */

  h1.book-collection-page-title
    font-size: 3rem

  .book-collection
    justify-content: center

  .book-listing
    width: 200px

  .book-listing-cover
    width: 200px
    height: min-content

  .book-main
    margin-bottom: 60px
    max-width: 300px

    h1.book-title
      font-size: 2.5rem

  .book-trifold
    margin-top: 40px
    flex-direction: column

  .book-cover
    height: min-content
    width: 250px
    margin-bottom: 40px

  .book-cover img
    width: 250px

  .book-reviews
    width: 250px
    margin: auto
    font-size: 0.9rem

  .book-buy
    margin-bottom: 20px

  .book-description
    max-width: 75vw

  %book-summary
    font-size: 1.3rem

  .book-column1
    margin: 0
    margin-bottom: 30px

  .book-column2
    margin: 0
    margin-bottom: 30px

  .book-tagline
    line-height: 2.3rem
    margin-bottom: 30px

  .book-conclusion
    margin: 0
    font-size: 0.9rem

  #book-buy-trifold
    display: none

  #book-buy-bottom
    display: flex
    margin: 50px auto 0 auto

  /* Series */

  .series-description
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 20px

  %series-summary
    font-size: 1.5rem
    width: 85%
    margin: 30px

  .series-column1
    float: left
    margin-bottom: 10px

  .series-column2
    float: right
    margin-bottom: 40px

  /* Blog */

  %blog-main

    h1
      font-size: 4rem
      text-align: center
      width: 100%

    h2
      font-size: 3rem
      text-align: left
      margin-left: 0

    h3
      font-size: 2.5rem
      text-align: left
      margin-left: 0

    h4
      font-size: 2rem
      text-align: left
      margin-left: 0

    h5
      font-size: 1.8rem
      text-align: left
      margin-left: 0

    h6
      font-size: 1.7rem
      text-align: left
      margin-left: 0

  .blog-category-menu-mobile-title
    display: block
    color: $black
    font-family: $roboto
    font-size: 0.9rem
    font-weight: 300
    text-transform: uppercase
    text-align: center
    letter-spacing: 3px
    margin: 20px
    text-decoration: none

    &:hover
      color: $boldpurple

  .blog-category-nav
    display: none
    justify-content: center

    ul
      flex-direction: column

  .blog-post-card
    width: 300px

  .blog-post-card-image

    img
      width: 300px

  p.blog-post-card-date
    font-family: $roboto
    font-weight: 300
    font-size: 0.9rem
    text-transform: uppercase
    color: $boldpurple
    letter-spacing: 2px
    margin: 10px 0 30px 0

  #down-arrow
    display: inline

  #up-arrow
    display: none

  /* Blog Posts */

  .post-main, .post-main-with-image

    blockquote
      margin: 30px 30px

    hr
      width: 100%

    li
      margin-left: 0

    p.special
      font-size: 3rem

  .post-image
    max-width: 100vw
    position: relative
    z-index: auto

    img
      max-width: 100vw

  .post-wrapper-with-image
    width: 100vw
    margin: auto
    padding: auto 10vw

  .post-main-with-image

    h2
      margin-left: 0

  /* Shop */

  .shop-base
    max-width: 300px

  .shop-menu-arrow-mobile
    display: flex

  .shop-nav-full
    display: none

  .shop-nav-mobile
    display: flex

  .shop-category-menu-mobile-title
    display: block
    color: $black
    font-family: $roboto
    font-size: 0.9rem
    font-weight: 300
    text-transform: uppercase
    text-align: center
    letter-spacing: 3px
    margin: 20px
    text-decoration: none

    &:hover
      color: $boldpurple

  // .shop-subcategory-item
  //   font-size: 0.8rem

  .shop-category-nav
    display: none
    justify-content: center

    ul
      flex-direction: column

  #shop-category-down-arrow
    display: inline

  #shop-category-up-arrow
    display: none

  .shop-product-collection
    max-width: 300px

  .shop-home-categories-display
    flex-direction: column

  .shop-home-category-card
    margin-top: 20px

  .shop-product-card
    width: 220px

  .shop-product-card-title
    font-size: 1.8rem

  .shop-product-card-description
    font-size: 0.9rem

  .shop-product-card-image

    img
      width: 220px

  /* Ads */

  // Better idea: Make a div wrapper around the iframe. Make a new one for mobile phones (etc.). Have two (or more) separate iframe ads. Hide one div and its contents on mobile and show the other; hide the other and show the first on desktop. Done.

  // Try CSS transform to make iframe content a percentage?
  // iframe
  //   transform: scale(0.5, 0.5)
  //   height: ?px
  //   width: ?px

  iframe.ad-iframe
    max-height: 150px

    *
      max-height: 150px

  %ad-iframe-base
    height: 150px

  .ad-iframe-book
    min-height: 150px

  .ad-base
    height: 150px

  %ad-image
    height: 150px
    width: min-content

    img
      height: 150px
      width: min-content

  .ad-image-book
    height: 150px
    width: min-content

    img
      height: 150px
      width: min-content


  /* Footer */

  .footer-gradient
    flex-direction: column

  %footer-section
    width: 90vw

  .footer-link
    padding-left: 0

/* Mobile Phones (Landscape) */
@media screen and (orientation: landscape) and (max-width: 999px)

  /* Header */

  .header-nav-full
    display: none

  .header-bars-mobile
    display: flex

  #mobile-header-menu
    display: none

  /* Homepage */

  .homea
    font-size: 4rem
    padding: 2%

  .homeb
    font-size: 1.2rem
    letter-spacing: 5px
    padding: 2%

  .homec
    font-size: 1.2rem
    letter-spacing: 5px
    padding: 2%

  .homed
    font-size: 4rem
    padding: 2%

  #joy
    font-size: 8rem
    padding: 2%

  #defeated
    font-size: 7rem
    padding: 2%

  /* Pages */

  %page-main
    max-width: 70vw

    %text-base
      font-size: 1.5rem
      line-height: 2.5rem

    %heading-base
      margin: 30px auto 5px auto

    h2
      margin-left: 10px

    h3
      margin-left: 30px

    h4
      margin-left: 30px

    h5
      margin-left: 70px

    h6
      margin-left: 70px

    li
      margin-left: 80px
      margin-right: 80px

  .page-wide
    max-width: 70vw

  /* Pages - Epilogue */

  p.epilogue-paragraph
    font-family: $roboto
    text-transform: uppercase
    font-weight: 300
    font-size: 1rem
    line-height: 1.7rem
    letter-spacing: 1px
    text-align: center
    margin: 20px auto

    a
      color: $white

      &:hover
        color: $lavender

  /* Pages - For Readers */

  .for-readers-display
    margin-top: 30px
    flex-wrap: wrap
    justify-content: center
    align-items: center

  /* Pages - Spoiler Section */

  .spoiler-post-display
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    align-items: flex-start
    align-self: center
    width: 90vw

  /* Collections */

  %collection-main
    max-width: 70vw

    %text-base
      font-size: 1.5rem
      line-height: 2.5rem

    %heading-base
      margin: 30px auto 5px auto

    h2
      margin-left: 10px

    h3
      margin-left: 30px

    h4
      margin-left: 30px

    h5
      margin-left: 70px

    h6
      margin-left: 70px

  /* Books */

  h1.book-collection-page-title
    font-size: 3.5rem
    margin: 5px

  .book-collection
    justify-content: space-around

    .book-listing
      display: flex
      flex-direction: column
      max-height: min-content
      width: 250px
      align-items: center

    .book-listing-cover
      max-height: 250px

    .book-listing-title
      font-size: 1.8rem
      margin: 10px

    .book-listing-subtitle
      font-size: 0.9rem
      margin: 0

    .book-listing-spacer
      margin-bottom: 50px

  .book-trifold
    min-height: min-content
    margin-top: 20px

  .book-cover
    height: min-content
    width: min-content

  .book-cover img
    height: min-content
    width: 200px

  .book-reviews
    height: min-content
    margin: auto 10px

  .book-description
    display: flex
    flex-direction: column
    align-items: center

  %book-summary
    font-size: 1.5rem
    margin-bottom: 30px

  .book-column1
    float: none

  .book-column2
    float: none

  .book-tagline
    line-height: 2rem
    max-width: 60vw

  .book-conclusion
    margin: 20px

  #book-buy-trifold
    display: none

  #book-buy-bottom
    display: flex
    margin: 50px auto 0 auto

  /* Series */

  .series-description
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 20px

  %series-summary
    font-size: 1.5rem
    width: 90%
    margin: 0
    margin-bottom: 30px

  .series-column1
    float: none

  .series-column2
    float: none

  /* Blog */

  .blog-category-menu-mobile-title
    display: block
    color: $black
    font-family: $roboto
    font-size: 0.9rem
    font-weight: 300
    text-transform: uppercase
    text-align: center
    letter-spacing: 3px
    margin: 20px
    text-decoration: none

    &:hover
      color: $boldpurple

  .blog-category-nav
    display: none
    justify-content: center

    ul
      flex-direction: column

  p.blog-post-card-date
    font-family: $roboto
    font-weight: 300
    font-size: 0.9rem
    text-transform: uppercase
    color: $boldpurple
    letter-spacing: 2px
    margin: 10px 0 30px 0

  #down-arrow
    display: inline

  #up-arrow
    display: none

  /* Blog Posts */

  .post-image
    max-width: 100vw

    img
      max-width: 100vw

  .post-main-with-image

    h2
      margin-left: 20px

  /* Shop */

  .shop-base
    max-width: 450px

  .shop-product-collection
    max-width: 450px

  .shop-home-categories-display
    flex-direction: column

  .shop-home-category-card
    margin-top: 20px

  /* Footer */

  .footer-gradient
    flex-wrap: wrap

  %footer-section
    width: 50%

/* Tablets (Landscape) */
@media screen and (orientation: landscape) and (min-width: 1000px) and (max-width: 1400px)

  /* Header */

  .header-nav-full
    display: none

  .header-bars-mobile
    display: flex

  /* Homepage */

  .homea
    font-size: 7.2rem
    padding: 2%

  .homeb
    font-size: 1.9rem
    letter-spacing: 5px
    padding: 2%

  .homec
    font-size: 1.9rem
    letter-spacing: 5px
    padding: 2%

  .homed
    font-size: 7rem
    padding: 2%

  #joy
    font-size: 14rem
    padding: 2%

  #defeated
    font-size: 10rem
    padding: 2%

  /* Pages */

  %page-main

    %text-base
      font-size: 1.5rem
      line-height: 2.5rem

    %heading-base
      margin: 30px auto 5px auto

    h2
      margin-left: 0

    h3
      margin-left: 10px

    h4
      margin-left: 10px

    h5
      margin-left: 50px

    h6
      margin-left: 50px

    li
      margin-right: 70px

  /* Pages - Epilogue */

  p.epilogue-paragraph
    font-family: $roboto
    text-transform: uppercase
    font-weight: 300
    font-size: 1rem
    line-height: 1.7rem
    letter-spacing: 1px
    text-align: center
    margin: 20px auto

    a
      color: $white

      &:hover
        color: $lavender

  /* Pages - For Readers */

  .for-readers-display
    margin-top: 50px
    flex-wrap: wrap
    justify-content: center
    align-items: center

  /* Pages - Spoiler Section */

  .spoiler-post-display
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    align-items: flex-start
    align-self: center

  /* Collections */

  %collection-main

    %text-base
      font-size: 1.5rem
      line-height: 2.5rem

    %heading-base
      margin: 30px auto 5px auto

    h2
      margin-left: 0

    h3
      margin-left: 10px

    h4
      margin-left: 10px

    h5
      margin-left: 50px

    h6
      margin-left: 50px

  /* Books */

  .book-main
    max-width: 100%
    margin-bottom: 100px

  .book-trifold
    min-height: min-content
    margin-top: 30px
    width: 100%

  .book-cover
    height: min-content
    width: min-content

  .book-cover img
    height: min-content
    width: 275px

  .book-reviews
    height: min-content
    margin: auto 20px

  .book-description
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 20px

  %book-summary
    font-size: 1.5rem
    margin-bottom: 30px

  .book-column1
    float: none

  .book-column2
    float: none

  /* Series */

  .series-description
    display: flex
    flex-direction: row
    align-items: top
    margin-top: 20px

  %series-summary
    font-size: 1.5rem
    margin: 30px

  .series-column1
    float: left

  .series-column2
    float: right

  /* Blog */

  .blog-category-menu-mobile-title
    display: block
    color: $black
    font-family: $roboto
    font-size: 0.9rem
    font-weight: 300
    text-transform: uppercase
    text-align: center
    letter-spacing: 3px
    margin: 20px
    text-decoration: none

    &:hover
      color: $boldpurple

  .blog-category-nav
    display: none
    justify-content: center

    ul
      flex-direction: column

  p.blog-post-card-date
    font-family: $roboto
    font-weight: 300
    font-size: 0.9rem
    text-transform: uppercase
    color: $boldpurple
    letter-spacing: 2px
    margin: 10px 0 30px 0

  #down-arrow
    display: inline

  #up-arrow
    display: none

  /* Blog Posts */

  .post-image
    max-width: 100vw

    img
      max-width: 100vw


  .post-main-with-image

    h2
      margin-left: 20px

    h3
      margin-left: 20px

    h4
      margin-left: 20px

    h5
      margin-left: 20px

    h6
      margin-left: 20px

  .post-sidebar-left
    margin-left: 3px

  .post-sidebar-right
    margin-right: 3px


  /* Shop */

  .shop-base
    max-width: 850px

  .shop-product-collection
    max-width: 850px

  .shop-home-category-card
    margin: 20px

  /* Ads */

  // For the place where ads appear, see Blog: post-sidebar-left and post-sidebar-right

  /* Footer */

  .footer-gradient
    flex-wrap: wrap

  %footer-section
    width: 50%

/* Tablets (Portrait) */
@media screen and (orientation: portrait) and (min-width: 481px) and (max-width: 820px)

  /* Header */

  .header-nav-full
    display: none

  .header-bars-mobile
    display: flex

  #mobile-header-menu
    display: none

  /* Homepage */

  .homea
    font-size: 6rem
    padding: 5%

  .homeb
    font-size: 2rem
    letter-spacing: 5px
    padding: 5%

  .homec
    font-size: 2rem
    letter-spacing: 5px
    padding: 5%

  .homed
    font-size: 6rem
    padding: 5%

  #joy
    font-size: 12rem
    padding: 5%

  #defeated
    font-size: 10rem
    padding: 5%

  /* Pages */

  %page-main
    max-width: 70vw

    %text-base
      font-size: 1.5rem
      line-height: 2.5rem

    %heading-base
      margin: 30px auto 5px auto

    h2
      margin-left: 0

    h3
      margin-left: 10px

    h4
      margin-left: 10px

    h5
      margin-left: 35px

    h6
      margin-left: 35px

  .page-wide
    max-width: 70vw

  /* Pages - Epilogue */

  p.epilogue-paragraph
    font-family: $roboto
    text-transform: uppercase
    font-weight: 300
    font-size: 1rem
    line-height: 1.7rem
    letter-spacing: 1px
    text-align: center
    margin: 20px auto

    a
      color: $white

      &:hover
        color: $lavender

  /* Pages - For Readers */

  .for-readers-display
    margin-top: 50px
    flex-wrap: wrap
    justify-content: center
    align-items: center

  /* Pages - Spoiler Section */

  .spoiler-post-display
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
    align-items: flex-start
    align-self: center

  /* Collections */

  %collection-main
    max-width: 70vw

    %text-base
      font-size: 1.5rem
      line-height: 2.5rem

    %heading-base
      margin: 30px auto 5px auto

    h2
      margin-left: 0

    h3
      margin-left: 10px

    h4
      margin-left: 10px

    h5
      margin-left: 35px

    h6
      margin-left: 35px

  /* Books */

  .book-main
    max-width: 100%

  .book-cover
    height: min-content
    width: min-content

  .book-cover img
    height: min-content
    width: 275px

  .book-description
    display: flex
    flex-direction: column
    align-items: center

  .book-column1
    float: none

  .book-column2
    float: none

  .book-conclusion
    margin: auto
    margin-bottom: 30px
    max-width: 80%

  #book-buy-trifold
    display: none

  #book-buy-bottom
    display: flex
    margin: 50px auto 0 auto

  /* Series */

  .series-description
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 20px

  %series-summary
    font-size: 1.5rem
    width: 90%
    margin: 30px

  .series-column1
    float: left
    margin-bottom: 0

  .series-column2
    float: right
    margin-bottom: 40px

  /* Blog */

  .blog-category-menu-mobile-title
    display: block
    color: $black
    font-family: $roboto
    font-size: 0.9rem
    font-weight: 300
    text-transform: uppercase
    text-align: center
    letter-spacing: 3px
    margin: 20px
    text-decoration: none

    &:hover
      color: $boldpurple

  .blog-category-nav
    display: none
    justify-content: center

    ul
      flex-direction: column

  p.blog-post-card-date
    font-family: $roboto
    font-weight: 300
    font-size: 0.9rem
    text-transform: uppercase
    color: $boldpurple
    letter-spacing: 2px
    margin: 10px 0 30px 0

  #down-arrow
    display: inline

  #up-arrow
    display: none

  /* Blog Posts */

  .post-image
    max-width: 100vw

    img
      max-width: 100vw

  /* Shop */

  .shop-base
    max-width: 600px

  .shop-product-collection
    max-width: 600px

  .shop-home-category-card
    margin-top: 20px


  /* Footer */

  .footer-gradient
    flex-wrap: wrap

  %footer-section
    width: 50%
