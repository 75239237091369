// PAGES //

.light
  background-color: $white
  color: $black

.dark
  background-image: radial-gradient($charcoal, $black)
  color: $white

%page-main
  min-height: min-content
  max-width: 600px
  margin: auto
  margin-bottom: 200px
  background-color: rgba(0, 0, 0, 0.0)
  display: flex
  flex-direction: column

  %heading-base
    font-family: $bebas
    margin-top: 40px
    margin-right: auto
    margin-bottom: 10px

  %text-base
    font-family: $minion
    font-size: 1.5rem
    line-height: 2.4rem
    max-width: 500px
    margin: auto

  h1
    @extend %heading-base
    font-size: 5rem
    text-align: center
    width: 100%

  h2
    @extend %heading-base
    font-size: 4rem
    text-align: left
    margin-left: -30px

  h3
    @extend %heading-base
    font-size: 3rem
    text-align: left
    margin-left: 20px

  h4
    @extend %heading-base
    font-size: 2.5rem
    text-align: left
    margin-left: 20px

  h5
    @extend %heading-base
    font-size: 2rem
    text-align: left
    margin-left: 50px

  h6
    @extend %heading-base
    font-size: 1.7rem
    text-align: left
    margin-left: 50px

  p
    @extend %text-base
    margin-top: 10px
    margin-bottom: 20px
    width: 100%

  li
    @extend %text-base
    margin-left: 60px
    margin-right: 60px
    margin-bottom: 10px

  blockquote
    color: $black
    font-family: $open-sans
    font-size: 1.1rem
    font-weight: 300
    line-height: 2rem
    background-color: $purple-background
    border-left: 7px solid $lighterboldpurple
    margin: 30px 90px
    padding: 20px

    %blockquote-text-base
      font-family: inherit
      font-size: inherit
      line-height: inherit
      font-weight: inherit

    p
      @extend %blockquote-text-base

      a
        color: $lighterboldpurple
        font-weight: 400

  p.warning
    color: $black
    font-family: $open-sans
    font-size: 1.1rem
    font-weight: 300
    line-height: 2rem
    background-color: $purple-background
    padding: 20px
    border-style: solid
    border-color: $lighterboldpurple
    border-width: 3px

    a
      color: $lighterboldpurple
      font-weight: 400

    strong
      font-weight: 600
      // text-transform: uppercase

  p.center
    text-align: center

  p.special
    font-family: $bebas
    font-size: 4rem
    // line-height: 3.5rem
    color: $black
    margin: 40px auto 10px auto

    a
      color: inherit
      text-decoration: underline
      text-decoration-color: $boldpurple

  hr
    color: $purple-background
    width: 90%
    height: 0.5px

.page-light
  @extend %page-main
  color: $black

  p, li

    a
      color: $boldpurple
      text-decoration: none

      &:hover
        text-decoration: underline
        text-decoration-color: $boldpurple

  blockquote
    color: $black
    font-family: $open-sans
    font-size: 1.1rem
    font-weight: 300
    line-height: 2rem
    background-color: rgb(86, 47, 224, 0.3)
    border-left: 7px solid $lighterboldpurple
    margin: 30px 90px
    padding: 20px

    %blockquote-text-base
      font-family: inherit
      font-size: inherit
      line-height: inherit
      font-weight: inherit

    p
      @extend %blockquote-text-base

      a
        color: $lighterboldpurple
        font-weight: 400

.page-dark
  @extend %page-main
  color: $white

.page-wide
  max-width: 1400px


// Epilogue Page

p.epilogue-paragraph
  font-family: $roboto
  text-transform: uppercase
  font-weight: 300
  font-size: 1rem
  line-height: 1.7rem
  letter-spacing: 1px
  text-align: center
  margin: 20px auto

  a
    color: $white

    &:hover
      color: $lavender

p.epilogue-image
  display: flex
  flex-direction: row
  align-content: center
  justify-content: center

  img
    margin: 20px auto

.get-epilogue
  display: flex
  flex-direction: column
  align-items: center

.epilogue-button
  width: 250px
  height: 50px
  font-family: $roboto
  font-size: 0.9rem
  font-weight: 300
  text-transform: uppercase
  letter-spacing: 1px
  color: $white
  background-color: $black
  margin: 20px auto
  border-width: 1px
  border-color: $white
  border-style: solid
  padding: 5px

  &:hover
    background-color: $white
    color: $black
    cursor: pointer

// For Readers

h1.for-readers-title
  text-align: center

.for-readers-display
  display: flex
  flex-direction: row
  justify-content: space-around
  margin-top: 100px

.for-readers-card
  display: flex
  flex-direction: column
  margin: 20px

.for-readers-image
  width: 400px
  height: 220px

.for-readers-text-link
  color: $white
  font-family: $bebas
  font-size: 2rem
  text-align: center
  text-transform: uppercase
  text-decoration: none
  margin: 20px auto

  &:hover
    text-decoration: underline

// Spoiler Section

.spoiler-section-book-display
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-around
  align-items: center
  align-self: center
  width: 120%

.spoiler-section-card
  margin: 10px
  width: max-content
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center

.spoiler-section-card-image
  height: 300px
  margin: auto

.spoiler-section-card-text-link
  font-family: $bebas
  font-size: 1.5rem
  color: $black
  text-decoration: none
  margin: 3px

  &:hover
    text-decoration: underline
    text-decoration-thickness: 3px
    text-decoration-color: $boldpurple

.spoiler-section-book-page-cover
  margin: 10px auto 30px auto
  width: min-content

.spoiler-post-display
  display: flex
  flex-direction: row
  flex-wrap: wrap
  // width: 120%
  width: 600px
  justify-content: center
  align-self: center
  padding: 0

  .blog-post-card
    width: 225px

  .blog-post-card-image

    img
      width: 225px

  .blog-post-card-title
    font-size: 2rem
    margin: 10px 0 5px 0

  p.blog-post-card-date
    font-size: 0.8rem
    line-height: 1.2rem

  .blog-post-card-excerpt
    font-size: 1.3rem
    line-height: 2rem

// For Writers

  /* See For Readers (above) */

// JC

p.jc-first-p
  margin-top: 40px

p.center-p
  text-align: center

p.jc2
  font-family: $bebas
  font-size: 3.5rem
  line-height: normal

p.jc3
  font-family: $bebas
  font-size: 2.5rem
  line-height: normal

p.jc4
  font-family: $bebas
  font-size: 3rem
  text-align: center
  line-height: normal

// Kamsa

.kamsa-book-display
  display: flex
  flex-direction: row
  flex-wrap: wrap
  width: 500px
  align-self: center
  justify-content: space-around
  align-items: center
  margin: 20px

.kamsa-book-cover
  margin: 10px 20px

  img
    width: 100px

.kamsa-blog-post-display
  display: flex
  flex-direction: row
  flex-wrap: wrap
  width: 600px
  justify-content: center

  .blog-post-card
    width: 225px

  .blog-post-card-image

    img
      width: 225px

  .blog-post-card-title
    font-size: 2rem
    margin: 10px 0 5px 0

  p.blog-post-card-date
    font-size: 0.8rem
    line-height: 1.2rem

  .blog-post-card-excerpt
    font-size: 1.3rem
    line-height: 2rem
