// BOOKS //

// Book Listing Collection Page //

.book-collection-page-title
  text-align: center

.book-collection
  display: flex
  flex-direction: row
  flex-wrap: wrap
  max-width: 80vw
  min-width: 50vw
  align-content: center
  align-items: flex-start
  justify-content: space-between
  margin: 10px auto

.book-listing
  width: 260px
  padding: 0

.book-listing-cover
  height: 400px
  margin: 0

.book-listing-cover-link
  border-width: 0

.book-listing-title
  font-family: $bebas
  font-size: 2rem
  max-width: 260px
  text-align: center
  margin: 10px

.book-listing-title-link
  color: $white
  text-decoration: none

  &:hover
    color: $white
    text-decoration: underline

.book-listing-subtitle
  font-family: $roboto
  max-width: 260px
  text-transform: uppercase
  font-weight: 300
  font-size: 1rem
  letter-spacing: 1px
  text-align: center
  margin: 10px

.book-listing-subtitle-link
  color: $white
  text-decoration: none

  &:hover
    color: $white
    text-decoration: underline

.book-listing-spacer
  margin-bottom: 20px

// Individiual Book Pages //

.book-main
  min-height: min-content
  max-width: 70vw
  margin: auto
  margin-bottom: 200px
  background-color: rgba(0, 0, 0, 0.0)
  color: $white
  display: flex
  flex-direction: column

.book-title
  font-family: $bebas
  font-size: 5rem
  text-align: center
  margin-top: 10px
  margin-bottom: 10px

.book-subtitle
  font-family: $roboto
  font-size: 1rem
  letter-spacing: 1px
  font-weight: 300
  text-transform: uppercase
  color: $white
  text-align: center
  margin-bottom: 20px

  a
    color: $white
    text-decoration: none

    &:hover
      color: $white
      text-decoration: underline

.book-trifold
  width: 100%
  min-height: 700px
  display: flex
  flex-direction: row
  justify-content: space-evenly
  margin-bottom: 20px

.book-cover
  height: 600px
  margin: auto
  width: 400px

.book-reviews
  margin: auto 50px
  display: flex
  flex-direction: column
  width: 300px

  h2
    font-family: $roboto
    font-size: 1.3rem
    font-weight: 600
    text-align: center
    text-transform: uppercase

.book-review
  font-family: $roboto
  font-weight: 300
  text-align: center
  text-transform: uppercase
  letter-spacing: 1px
  line-height: 1.6rem
  margin: 20px

.book-buy
  display: flex
  flex-direction: column
  width: 250px
  align-items: center
  justify-items: center
  margin: auto
  padding-left: 0

  a
    &:hover
      cursor: default

  h2
    font-family: $roboto
    font-size: 1.3rem
    font-weight: 600
    text-align: center
    text-transform: uppercase

  button
    width: 150px
    height: 50px
    font-family: $roboto
    font-size: 0.9rem
    font-weight: 300
    text-transform: uppercase
    letter-spacing: 1px
    color: $white
    background-color: $black
    margin: 15px
    border-width: 1px
    border-color: $white
    border-style: solid
    padding: 5px

    &:hover
      background-color: $white
      color: $black
      cursor: pointer

.book-description
  margin: auto

%book-summary
  font-family: $minion
  font-size: 1.6rem
  line-height: 2.8rem
  max-width: 525px
  margin: auto
  display: block

.book-column1
  @extend %book-summary
  float: left
  margin: 20px

.book-column2
  @extend %book-summary
  float: right
  margin: 20px
  margin-bottom: 40px

.book-centered-column
  @extend %book-summary
  text-align: center

.book-centered-column-last
  @extend %book-summary
  text-align: center
  margin-bottom: 40px

.book-tagline
  font-family: $roboto
  font-size: 1.5rem
  font-weight: 600
  text-align: center
  text-transform: uppercase
  clear: both
  display: block

.book-conclusion
  font-family: $roboto
  font-size: 1.2rem
  letter-spacing: 1px
  line-height: 1.8rem
  font-weight: 300
  text-align: center
  text-transform: uppercase
  clear: both
  display: block
  margin: 20px 200px
  padding-top: 20px
  max-width: 700px

  a
    color: $white
    text-decoration: underline

    &:hover
      color: $lavender

#book-buy-bottom
  display: none

/* Series Collection Pages */

.series-description
  margin: auto
  display: flex
  flex-direction: row
  width: 100%

%series-summary
  font-family: $minion
  font-size: 1.6rem
  line-height: 2.8rem
  max-width: 525px
  margin: auto
  display: block

.series-column1
  @extend %series-summary
  float: left
  margin: 30px
  margin-left: 0
  margin-bottom: 40px
  width: 50%

.series-column2
  @extend %series-summary
  float: right
  margin: 30px
  margin-right: 0px
  margin-bottom: 40px
  width: 50%
