// COLLECTIONS //

%collection-main
  min-height: min-content
  max-width: 1000px
  margin: auto
  margin-bottom: 200px
  background-color: rgba(0, 0, 0, 0.0)

  %heading-base
    font-family: $bebas
    margin-top: 40px
    margin-right: auto
    margin-bottom: 10px

  %text-base
    font-family: $minion
    font-size: 1.6rem
    line-height: 2.8rem
    max-width: 500px
    margin: auto

  h1
    @extend %heading-base
    font-size: 5rem
    text-align: center

  h2
    @extend %heading-base
    font-size: 4rem
    text-align: left
    margin-left: -30px

  h3
    @extend %heading-base
    font-size: 3rem
    text-align: left
    margin-left: 20px

  h4
    @extend %heading-base
    font-size: 2.5rem
    text-align: left
    margin-left: 20px

  h5
    @extend %heading-base
    font-size: 2rem
    text-align: left
    margin-left: 50px

  h6
    @extend %heading-base
    font-size: 1.7rem
    text-align: left


.collection-light
  @extend %collection-main
  color: $black

.collection-dark
  @extend %collection-main
  color: $white
