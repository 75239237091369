// FOOTER //

/* Footer Gradient */

.footer-gradient
  background-image: radial-gradient($charcoal, $black)
  color: $white
  font-family: $roboto
  display: flex
  flex-direction: row
  justify-content: space-between
  padding: 20px

/* Footer Sections */

%footer-section
  display: flex
  flex-direction: column
  padding: 10px
  width: 25%

  h3
    color: $white
    font-family: $bebas
    font-size: 2.5rem
    font-weight: lighter
    margin: 0


.footer-explore
  @extend %footer-section

.footer-signup
  @extend %footer-section

.footer-legal
  @extend %footer-section

// .footer-search
//   @extend %footer-section

.footer-resources
  @extend %footer-section

.footer-nav-list
  list-style: none
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: stretch
  padding: 0

.footer-nav-item
  padding: 15px
  padding-left: 0

.footer-link
  color: $white
  font-family: $roboto
  font-size: 0.9rem
  font-weight: lighter
  line-height: 1rem
  letter-spacing: 2px
  text-decoration: none
  text-transform: uppercase
  padding: 10px
  border-style: solid
  border-width: 1px
  border-color: rgb(0, 0, 0, 0.0)

  &:hover
    border-style: solid
    border-width: 1px
    border-color: $white
