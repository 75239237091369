// VARS //
// Sass Variables //


// COLORS //

$white: #fff
$black: #000
$charcoal: #333
$gray: #555
$boldpurple: #522FD2
$lighterboldpurple: #562FE1
$lavender: #9279ec
$purple-background: rgb(86, 47, 224, 0.3)


// FONTS //

/*Font Families */

$bebas: 'bebas-neue', sans-serif
$minion: 'minion-3-display', serif
$roboto: 'Roboto', sans-serif
$open-sans: 'Open Sans', sans-serif

/* Font Sizes */

$epilogue-para: 1.2rem


// SIZES //

/* Ads */

$ad-book-height-base: 320px
$ad-apparel-height-base: 250px

$ad-book-height-medium: 300px
$ad-book-height-small: 150px

$ad-apparel-height-medium: 200px
$ad-apparel-height-small: 150px

$ad-title-font-size-large: 1.5rem
$ad-title-font-size-medium: 1.2rem
$ad-title-font-size-small: 1rem

$ad-description-font-size-large: 0.9rem
$ad-description-font-size-medium: 0.8rem
$ad-description-font-size-small: 0.7rem

$ad-description-line-height-large: 1.3rem
$ad-description-line-height-medium: 1.2rem
$ad-description-line-height-small: 1.1rem
