// SHOP //

.shop-base
  min-height: min-content
  max-width: 1250px
  margin: auto
  margin-bottom: 200px
  background-color: rgba(0, 0, 0, 0.0)
  color: $black

  %heading-base
    font-family: $bebas
    margin-top: 40px
    margin-right: auto
    margin-bottom: 10px

  %text-base
    font-family: $minion
    font-size: 1.6rem
    line-height: 2.8rem
    max-width: 500px
    margin: auto

  h1
    @extend %heading-base
    font-size: 5rem
    text-align: center

  h2
    @extend %heading-base
    font-size: 4rem
    text-align: left
    margin-left: -30px

  h3
    @extend %heading-base
    font-size: 3rem
    text-align: left
    margin-left: 20px

  h4
    @extend %heading-base
    font-size: 2.5rem
    text-align: left
    margin-left: 20px

  h5
    @extend %heading-base
    font-size: 2rem
    text-align: left
    margin-left: 50px

  h6
    @extend %heading-base
    font-size: 1.7rem
    text-align: left

.shop-wrapper
  display: flex
  flex-direction: row

// Mobile Shop Category Menu

.shop-menu-arrow-mobile
  display: none
  background-color: $white
  flex-direction: column
  align-items: center
  margin: 15px 5px
  padding: 0
  height: 62px
  position: fixed

  p
    font-family: $roboto
    text-transform: uppercase
    font-size: 0.8rem
    font-weight: 500
    letter-spacing: 1px
    line-height: 0
    color: $boldpurple
    margin: 7px
    padding: 0

  i
    margin: 15px 0 0 0


  &:hover
    cursor: pointer

.shop-nav-mobile
  display: none
  flex-direction: column
  min-width: 150px
  max-width: 50%
  margin: 35px 0 0 0
  position: fixed

.shop-nav-ul-mobile
  display: none
  flex-direction: column
  padding: 5px
  background-color: $white
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7)
  height: min-content

.shop-nav-li-mobile
  list-style-type: none
  margin: 20px
  line-height: 1.5rem

.shop-category-menu-heading
  font-family: $bebas
  font-size: 1.8rem
  text-transform: uppercase
  color: $black
  font-weight: bold
  text-decoration: none
  letter-spacing: 1px

  &:hover
    text-decoration: underline
    text-decoration-color: $boldpurple
    text-decoration-thickness: 3px

.shop-subcategory-menu
  display: flex
  flex-direction: column

.shop-subcategory-ul
  padding: 0px

.shop-subcategory-li
  margin: 10px
  margin-left: 15px
  list-style-type: none

.shop-subcategory-item
  font-family: $roboto
  font-size: 0.9rem
  text-transform: uppercase
  color: $black
  font-weight: 300
  text-decoration: none
  letter-spacing: 1px

  &:hover
    color: $boldpurple

.current-shop-category
  text-decoration: underline
  text-decoration-thickness: 3px
  text-decoration-color: $boldpurple

.current-shop-subcategory
  color: $boldpurple

#shop-mobile-menu
  display: none

// Shop Category Menu (Non-Mobile)

.shop-nav-full
  display: flex
  flex-direction: column
  min-width: min-content
  max-width: 30%
  margin: 20px

.shop-nav-ul
  padding: 5px

.shop-nav-li
  list-style-type: none
  margin: 20px
  line-height: 1.5rem

.shop-category-menu-heading
  font-family: $bebas
  font-size: 1.8rem
  text-transform: uppercase
  color: $black
  font-weight: bold
  text-decoration: none
  letter-spacing: 1px

  &:hover
    text-decoration: underline
    text-decoration-color: $boldpurple
    text-decoration-thickness: 3px

.shop-subcategory-menu
  display: flex
  flex-direction: column

.shop-subcategory-ul
  padding: 0px

.shop-subcategory-li
  margin: 10px
  margin-left: 15px
  list-style-type: none

.shop-subcategory-item
  font-family: $roboto
  font-size: 0.9rem
  text-transform: uppercase
  color: $black
  font-weight: 300
  text-decoration: none
  letter-spacing: 1px

  &:hover
    color: $boldpurple

.current-shop-category
  text-decoration: underline
  text-decoration-thickness: 3px
  text-decoration-color: $boldpurple

.current-shop-subcategory
  color: $boldpurple

// Shop Product Collection

.shop-product-collection
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: center
  width: 1250px

// Shop Category Card

// .shop-category-card
//   margin: auto

// Shop Product Card

.shop-product-card
  background-color: $white
  width: 350px
  height: min-content
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7)
  margin: 30px
  padding: 0

.shop-product-card-image

  img
    width: 350px

.shop-product-card-text
  margin: 15px
  padding: 10px 5px 10px 5px

.shop-product-card-title
  font-family: $bebas
  font-size: 2rem
  color: $black
  text-decoration: none
  text-transform: uppercase
  margin: 10px 0 10px 0

.shop-product-card-category
  font-family: $roboto
  font-weight: 300
  font-size: 0.8rem
  text-transform: uppercase
  color: $boldpurple
  letter-spacing: 2px
  margin: 10px 0 30px 0

.shop-product-card-subcategory
  font-family: $roboto
  font-weight: 300
  font-size: 0.8rem
  text-transform: uppercase
  color: $boldpurple
  letter-spacing: 2px
  margin: 10px 0 30px 0

.shop-product-card-category-divider
  font-family: $roboto
  font-weight: 300
  font-size: 0.8rem
  text-transform: uppercase
  color: $boldpurple
  letter-spacing: 2px
  margin: 10px 0 30px 0

.shop-product-card-description
  font-family: $open-sans
  font-size: 1rem
  font-weight: 300
  line-height: 1.7rem
  margin: 20px 0

.shop-product-card-price-and-buy
  // padding: 0 20px 0 0
  padding: 0
  display: flex
  flex-direction: row
  // justify-content: space-between
  justify-content: center
  margin: auto
  align-items: center

.shop-product-card-qualifier-and-price
  display: flex
  flex-direction: column
  padding: 0

.shop-product-card-price-qualifier
  font-family: $roboto
  font-size: 0.8rem
  text-transform: uppercase
  color: $boldpurple
  font-weight: 300
  text-decoration: none
  letter-spacing: 2px
  float: left
  margin: 0

.shop-product-card-price
  font-family: $open-sans
  font-size: 1.2rem
  text-transform: uppercase
  color: $boldpurple
  font-weight: bold
  text-decoration: none
  float: left
  align-self: center
  margin: 0

.shop-product-card-qualified-price
  font-family: $open-sans
  font-size: 1.2rem
  text-transform: uppercase
  color: $boldpurple
  font-weight: bold
  text-decoration: none
  float: left
  align-self: flex-start
  margin: 0

.shop-product-card-buy-button
  background-color: $black
  padding: 0
  width: 100px
  display: flex
  justify-content: center
  float: right
  border-width: 0
  height: min-content

  &:hover
    cursor: pointer

  p
    color: $white
    text-transform: uppercase
    font-family: $roboto
    font-size: 0.9rem
    font-weight: normal
    letter-spacing: 2px

// Shop Homepage

.shop-home-categories-display
  display: flex
  flex-direction: row
  justify-content: center
  flex-wrap: wrap
  margin: 50px 20px

.shop-home-category-card
  display: flex
  flex-direction: column
  align-items: center
  width: 250px

.shop-home-category-image
  width: 200px

.shop-home-category-title
  font-family: $bebas
  font-size: 2.2rem
  text-align: center
  margin-top: 15px

  a
    color: inherit
    text-decoration: none

    &:hover
      text-decoration: underline
      text-decoration-thickness: 5px
      text-decoration-color: $boldpurple
